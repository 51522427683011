


.loader {
	border: 1px solid currentcolor;
	border-radius: 50%;
	animation: 1s loader-04 linear infinite;
	position: relative;
	&:before {
		content: '';
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		top: -.2em;
		left: 50%;
		border: .2em solid currentcolor;
		border-radius: 50%;
	}
}

@keyframes loader-04 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
