@font-face {
    font-family: 'TT_Fors';
    font-weight: 300;
    src: local('TT_Fors'), url(./TT_Fors_Trial_Light.ttf) format('truetype');
}

@font-face {
  font-family: 'TT_Fors';
  font-weight: 400;
  src: local('TT_Fors'), url(./TT_Fors_Trial_Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'TT_Fors';
  font-weight: 500;
  src: local('TT_Fors'), url(./TT_Fors_Trial_Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'TT_Fors';
  font-weight: 600;
  src: local('TT_Fors'), url(./TT_Fors_Trial_Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'TT_Fors';
  font-weight: 700;
  src: local('TT_Fors'), url(./TT_Fors_Trial_ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'TT_Fors';
  font-weight: 900;
  src: local('TT_Fors'), url(./TT_Fors_Trial_Black.ttf) format('truetype');
}